<template>
    <div class="server-error-component-container">
        <div>
            <img src="../../../assets/shared-components-assets/server-error-component/error_500.jpg" alt="bébé qui joue avec un cable">
            <div class="back-to-menu">
                <p>Oups, problème technique !</p>
                <router-link to="/">Retour au l'acceuil</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ServerErrorComponent",
    components: {
    },
};
</script>

<style lang="scss">
    @import "server-error-component.scss";
</style>